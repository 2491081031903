<!-- =========================================================================================
File Name: RegisterJWT.vue
Description: Register Page for JWT
----------------------------------------------------------------------------------------
Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="clearfix">
    <div class="vx-row">
      <vs-input
        class="vx-col w-1/2"
        data-vv-validate-on="blur"
        label-placeholder="First Name"
        name="first_name"
        placeholder="First Name"
        v-model="first_name"  @keyup.enter="registerUserJWt"
        v-validate="'required|alpha_dash|min:3'"/>
      <!--      <span class="text-danger text-sm">{{ errors.first('first_name') }}</span>-->

      <vs-input
        class="vx-col w-1/2"
        data-vv-validate-on="blur"
        label-placeholder="Last Name"
        name="last_name"
        placeholder="Last Name"
        v-model="last_name"  @keyup.enter="registerUserJWt"
        v-validate="'required|alpha_dash|min:3'"/>
      <!--      <span class="text-danger text-sm">{{ errors.first('last_name') }}</span>-->
    </div>


    <div class="vx-row">
      <vs-input
        class="vx-col w-full mt-6"
        data-vv-validate-on="blur"
        label-placeholder="Email"
        name="email"
        placeholder="Email"
        type="email"
        v-model="email"  @keyup.enter="registerUserJWt"
        v-validate="'required|email'"/>
      <!--      <span class="text-danger text-sm">{{ errors.first('email') }}</span>-->
    </div>

    <div class="vx-row">
      <vs-input
        class="vx-col w-1/2 mt-6"
        data-vv-validate-on="blur"
        label-placeholder="Password"
        name="password"
        placeholder="Password"
        ref="password"
        type="password"
        v-model="password"  @keyup.enter="registerUserJWt"
        v-validate="'required|min:6'"/>
      <!--      <span class="text-danger text-sm">{{ errors.first('password') }}</span>-->

      <vs-input
        class="vx-col w-1/2 mt-6"
        data-vv-as="password"
        data-vv-validate-on="blur"
        label-placeholder="Confirm Password"
        name="confirm_password"
        placeholder="Confirm Password"
        type="password"
        v-model="confirm_password"  @keyup.enter="registerUserJWt"
        v-validate="'min:6|confirmed:password'"/>
      <!--      <span class="text-danger text-sm">{{ errors.first('confirm_password') }}</span>-->
    </div>

    <vs-checkbox class="mt-6" v-model="isTermsConditionAccepted">I accept the terms & conditions.</vs-checkbox>
    <vs-button class="mt-6" to="/login" type="border">Login</vs-button>
    <vs-button :disabled="!validateForm" @click="registerUserJWt" class="float-right mt-6">Register
    </vs-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      confirm_password: '',
      isTermsConditionAccepted: true,
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.first_name !== '' && this.last_name !== '' && this.email !== '' &&
        this.password !== '' && this.confirm_password !== '' && this.isTermsConditionAccepted === true;
    },
  },
  methods: {
    checkLogin() {
      if (this.$store.state.auth.isUserLoggedIn()) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userInfo');
      }
      return true;
    },
    async registerUserJWt() {
      // If form is not validated or user is already login return
      if (!this.validateForm || !this.checkLogin()) return;

      this.$vs.loading();

      const payload = {
        userDetails: {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          password: this.password,
          confirm_password: this.confirm_password,
          recaptcha: await this.$recaptcha('login'),
        },
        notify: this.$vs.notify,
      };
      this.$store.dispatch('auth/registerUserJWT', payload)
        .then(() => {
          this.$gtag.event('auth_register_success', {
            event_category: 'authentication',
            event_label: 'auth_register',
          });
        })
        .catch((response) => {
          this.$vs.loading.close();

          if (response.response) {
            response = response.response;
          }

          if (response.status === 400 || response.status === 403) {
            this.$gtag.event('auth_register_failure', {
              event_category: 'authentication',
              event_label: 'auth_failure',
            });

            return this.$vs.notify({
              title: 'Failed to register',
              text: response.data.error.description,
              iconPack: 'feather',
              icon: 'icon-user-x',
              color: 'danger',
            });
          }

          if (response.message) {
            return this.$vs.notify({
              title: 'Failed to register',
              text: response.message || 'test',
              iconPack: 'feather',
              icon: 'icon-user-x',
              color: 'danger',
            });
          }
        });
    },
  },
};
</script>
